import React from "react";

import Nav from "./components/Nav/Nav";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Body from "./components/Body/Body";

import "./App.css";

function App() {
  return (
    <div className="App">

      <Nav/>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
