import React from "react";
import styles from "./Footer.module.css";

function Footer() {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerText}>
                <div className={styles.footerBottom}>Follow Us On -</div>
                <div className={styles.footerBottom}>
                    <ul>
                        <li><a href="https://www.facebook.com/anwmark">Facebook</a></li>
                        <li><a href="https://www.instagram.com/anwmark">Instagram</a></li>
                        <li><a href="https://www.youtube.com/@anwmark4356">Youtube</a></li>
                    </ul>
                </div>
            </div>
            <div className={styles.footerImg}>
                Designed with <img src="/images/heart.png"/> by- <a href="https://www.anwmark.in"> ANW Mark Web Solutions</a>
            </div>
            <div className={styles.footerText}>
                <div className={styles.footerBottom}>For any queries or suggessions, please reach us.</div>
                <div className={styles.footerBottom}>
                    <ul>
                        <li><a href="https://wa.me/+917749995045">Whatsapp</a></li>
                        <li><a href="mailto:contact@anwmark.in">EMAIL</a></li>
                        <li><a href="https://anwmark.in/contact">LIVE CHAT</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;