import React from "react";
import styles from "./Nav.module.css";

function Nav() {
    return (
        <div className={styles.navigationContainer}>
            <div className={styles.navLink}>
                <a href="https://anwmark.in/service">Other Services</a>
            </div>
            <div className={styles.navImg}>
                <a href="https://anwmark.in"><img src="logo.png" alt="ANW Mark Web Solutions official"/></a>
            </div>
            <div className={styles.navLink}>
               <a href="https://anwmark.in/contact">Contact Us</a>
            </div>
        </div>
    );
}

export default Nav;