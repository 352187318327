import React from "react";

import ReactGA from "react-ga4";
import AppConfig from "../../App.config";

import resumeSvg from "../../assets/sid.png";

import styles from "./Header.module.css";

ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);
function Header() {

  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: "/" });

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <p className={styles.heading}>
          A <span>Resume</span> that stands out..!!
        </p>
        <p className={styles.heading}>
          Make your own resume. <span>It's FREE</span>
        </p>
      </div>
      <div className={styles.right}>
        <img src={resumeSvg} alt="Resume" />
      </div>
      
    </div>
  );
}

export default Header;
