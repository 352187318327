import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  AtSign,
  Calendar,
  GitHub,
  Linkedin,
  MapPin,
  Paperclip,
  Phone,
  Info
} from "react-feather";

import styles from "./Resume.module.css";

const Resume = forwardRef((props, ref) => {
  
  console.log("Resume props", props);

  const information = props.information;
  const sections = props.sections;
  const containerRef = useRef();
  const nameRef = useRef();
  const titleRef = useRef();
  const addressRef = useRef();
  const linkRef = useRef();
  const column1Ref = useRef();
  const column2Ref = useRef();
  const sectionTitle1Ref = useRef();
  const sectionTitle2Ref = useRef();
  const sectionTitle3Ref = useRef();
  const sectionTitle4Ref = useRef();
  const sectionTitle5Ref = useRef();
  const sectionTitle6Ref = useRef();
  const sectionTitle7Ref = useRef();
  const sectionTitle8Ref = useRef();

  const [columns, setColumns] = useState([[], []]);
  const [source, setSource] = useState("");
  const [target, seTarget] = useState("");

  const info = {
    workExp: information[sections.workExp],
    project: information[sections.project],
    achievement: information[sections.achievement],
    skills: information[sections.skills],
    education: information[sections.education],
    basicInfo: information[sections.basicInfo],
    summary: information[sections.summary],
    hobbies: information[sections.hobbies],
    other: information[sections.other],
  };

  const getFormattedDate = (value) => {
    if (!value) return "";
    const date = new Date(value);

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const sectionDiv = {
    [sections.workExp]: (
      <div
        key={"workexp"}
        draggable
        onDragOver={() => seTarget(info.workExp?.id)}
        onDragEnd={() => setSource(info.workExp?.id)}
        className={`${styles.section} ${
          info.workExp?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle1Ref} className={styles.sectionTitle}>{info.workExp.sectionTitle}</div>
        <div className={styles.content}>
          {info.workExp?.details?.map((item) => (
            <div className={styles.item} key={item.title}>
              {item.title ? (
                <p className={styles.title}>{item.title}</p>
              ) : (
                <span />
              )}
              {item.companyName ? (
                <p className={styles.subTitle}>{item.companyName}</p>
              ) : (
                <span />
              )}
              {item.certificationLink ? (
                <a className={styles.link} href={item.certificationLink}>
                  <Paperclip />
                  {item.certificationLink}
                </a>
              ) : (
                <span />
              )}
              {item.startDate && item.endDate ? (
                <div className={styles.date}>
                  <Calendar /> {getFormattedDate(item.startDate)}-
                  {getFormattedDate(item.endDate)}
                </div>
              ) : (
                <div />
              )}
              {item.location ? (
                <p className={styles.date}>
                  <MapPin /> {item.location}
                </p>
              ) : (
                <span />
              )}
              {item.points?.length > 0 ? (
                <ul className={styles.points}>
                  {item.points?.map((elem, index) => (
                    <li className={styles.point} key={elem + index}>
                      {elem}
                    </li>
                  ))}
                </ul>
              ) : (
                <span />
              )}
            </div>
          ))}
        </div>
      </div>
    ),
    [sections.project]: (
      <div
        key={"project"}
        draggable
        onDragOver={() => seTarget(info.project?.id)}
        onDragEnd={() => setSource(info.project?.id)}
        className={`${styles.section} ${
          info.project?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle2Ref} className={styles.sectionTitle}>{info.project.sectionTitle}</div>
        <div className={styles.content}>
          {info.project?.details?.map((item) => (
            <div className={styles.item}>
              {item.title ? (
                <p className={styles.title}>{item.title}</p>
              ) : (
                <span />
              )}
              {item.link ? (
                <a className={styles.link} href={item.link}>
                  <Paperclip />
                  {item.link}
                </a>
              ) : (
                <span />
              )}
              {item.github ? (
                <a className={styles.link} href={item.github}>
                  <GitHub />
                  {item.github}
                </a>
              ) : (
                <span />
              )}
              {item.overview ? (
                <p className={styles.overview}>{item.overview} </p>
              ) : (
                <span />
              )}
              {item.points?.length > 0 ? (
                <ul className={styles.points}>
                  {item.points?.map((elem, index) => (
                    <li className={styles.point} key={elem + index}>
                      {elem}
                    </li>
                  ))}
                </ul>
              ) : (
                <span />
              )}
            </div>
          ))}
        </div>
      </div>
    ),
    [sections.education]: (
      <div
        key={"education"}
        draggable
        onDragOver={() => seTarget(info.education?.id)}
        onDragEnd={() => setSource(info.education?.id)}
        className={`${styles.section} ${
          info.education?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle3Ref} className={styles.sectionTitle}>
          {info.education?.sectionTitle}
        </div>
        <div className={styles.content}>
          {info.education?.details?.map((item) => (
            <div className={styles.item}>
              {item.title ? (
                <p className={styles.title}>{item.title}</p>
              ) : (
                <span />
              )}
              {item.college ? (
                <p className={styles.subTitle}>{item.college}</p>
              ) : (
                <span />
              )}
              {item.startDate && item.endDate ? (
                <div className={styles.date}>
                  <Calendar /> {getFormattedDate(item.startDate)} -
                  {getFormattedDate(item.endDate)}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    ),
    [sections.achievement]: (
      <div
        key={"achievement"}
        draggable
        onDragOver={() => seTarget(info.achievement?.id)}
        onDragEnd={() => setSource(info.achievement?.id)}
        className={`${styles.section} ${
          info.achievement?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle4Ref} className={styles.sectionTitle}>
          {info.achievement?.sectionTitle}
        </div>
        <div className={styles.content}>
          {info.achievement?.points?.length > 0 ? (
            <ul className={styles.numbered}>
              {info.achievement?.points?.map((elem, index) => (
                <li className={styles.point} key={elem + index}>
                  {elem}
                </li>
              ))}
            </ul>
          ) : (
            <span />
          )}
        </div>
      </div>
    ),
    [sections.skills]: (
      <div
        key={"skills"}
        draggable
        onDragOver={() => seTarget(info.skills?.id)}
        onDragEnd={() => setSource(info.skills?.id)}
        className={`${styles.section} ${
          info.skills?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle5Ref} className={styles.sectionTitle}>
          {info.skills?.sectionTitle}
        </div>
        <div className={styles.content}>
          {info.skills?.points?.length > 0 ? (
            <ul className={styles.numbered}>
              {info.skills?.points?.map((elem, index) => (
                <li className={styles.point} key={elem + index}>
                  {elem}
                </li>
              ))}
            </ul>
          ) : (
            <span />
          )}
        </div>
      </div>
    ),
    [sections.summary]: (
      <div
        key={"summary"}
        draggable
        onDragOver={() => seTarget(info.summary?.id)}
        onDragEnd={() => setSource(info.summary?.id)}
        className={`${styles.section} ${
          info.summary?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle6Ref} className={styles.sectionTitle}>{info.summary?.sectionTitle}</div>
        <div className={styles.content}>
          <p className={styles.overview}>{info.summary?.detail}</p>
        </div>
      </div>
    ),
    [sections.hobbies]: (
      <div
        key={"hobbies"}
        draggable
        onDragOver={() => seTarget(info.hobbies?.id)}
        onDragEnd={() => setSource(info.hobbies?.id)}
        className={`${styles.section} ${
          info.hobbies?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle7Ref} className={styles.sectionTitle}>{info.hobbies?.sectionTitle}</div>
        <div className={styles.content}>
          <p className={styles.overview}>{info?.hobbies?.detail}</p>
        </div>
      </div>
    ),
    [sections.other]: (
      <div
        key={"other"}
        draggable
        onDragOver={() => seTarget(info.other?.id)}
        onDragEnd={() => setSource(info.other?.id)}
        className={`${styles.section} ${
          info.other?.sectionTitle ? "" : styles.hidden
        }`}
      >
        <div ref={sectionTitle8Ref} className={styles.sectionTitle}>{info.other?.sectionTitle}</div>
        <div className={styles.content}>
          <p className={styles.overview}>{info?.other?.detail}</p>
        </div>
      </div>
    ),
  };

  const swapSourceTarget = (source, target) => {
    if (!source || !target) return;
    const tempColumns = [[...columns[0]], [...columns[1]]];

    let sourceRowIndex = tempColumns[0].findIndex((item) => item === source);
    let sourceColumnIndex = 0;
    if (sourceRowIndex < 0) {
      sourceColumnIndex = 1;
      sourceRowIndex = tempColumns[1].findIndex((item) => item === source);
    }

    let targetRowIndex = tempColumns[0].findIndex((item) => item === target);
    let targetColumnIndex = 0;
    if (targetRowIndex < 0) {
      targetColumnIndex = 1;
      targetRowIndex = tempColumns[1].findIndex((item) => item === target);
    }

    const tempSource = tempColumns[sourceColumnIndex][sourceRowIndex];
    tempColumns[sourceColumnIndex][sourceRowIndex] =
      tempColumns[targetColumnIndex][targetRowIndex];

    tempColumns[targetColumnIndex][targetRowIndex] = tempSource;

    setColumns(tempColumns);
  };

  useEffect(() => {
    setColumns([
      [sections.project, sections.education, sections.hobbies, sections.summary],
      [sections.workExp, sections.skills, sections.achievement, sections.other],
    ]);
  }, []);

  useEffect(() => {
    swapSourceTarget(source, target);
  }, [source]);

  useEffect(() => {
    const container = containerRef.current;
    const col1 = column1Ref.current;
    const col2 = column2Ref.current;
    const heading = nameRef.current;
    const designation = titleRef.current;
    const address = addressRef.current;
    const infoLink = linkRef.current;
    const sectionTitle1 = sectionTitle1Ref.current;
    const sectionTitle2 = sectionTitle2Ref.current;
    const sectionTitle3 = sectionTitle3Ref.current;
    const sectionTitle4 = sectionTitle4Ref.current;
    const sectionTitle5 = sectionTitle5Ref.current;
    const sectionTitle6 = sectionTitle6Ref.current;
    const sectionTitle7 = sectionTitle7Ref.current;
    const sectionTitle8 = sectionTitle8Ref.current;

    if (!props.activeColor || !container) return;
    container.style.setProperty("--color", props.activeColor);

    if(!props.activeTemplate.bgGroundClr || !container) return;
    if(props.activeTemplate.bgGroundClr) {
      container.style.setProperty("background", props.activeTemplate.bgGroundClr);
      container.style.setProperty("background-size", "850px 1200px");
      container.style.setProperty("color", props.activeTemplate.allTextClr);
    }

    if(!props.activeTemplate.headingColor || !props.activeTemplate.headingSize || !heading) return;
    if(props.activeTemplate.headingColor && props.activeTemplate.headingSize) {
      heading.style.setProperty("color", props.activeTemplate.headingColor);
      heading.style.setProperty("font-weight", props.activeTemplate.headingSize);
      heading.style.setProperty("text-align", props.activeTemplate.headingAlign);
    }

    if(!props.activeTemplate.titleAlign || !designation) return;
    if(props.activeTemplate.titleAlign) {
      designation.style.setProperty("text-align", props.activeTemplate.titleAlign);
    }

    if(!props.activeTemplate.addressAlign || !address) return;
    if(props.activeTemplate.addressAlign) {
      address.style.setProperty("text-align", props.activeTemplate.addressAlign);
    }

    if(!props.activeTemplate.linkFlex || !props.activeTemplate.linkTextJustify || !infoLink) return;
    if(props.activeTemplate.linkFlex && props.activeTemplate.linkTextJustify) {
      infoLink.style.setProperty("flex-direction", props.activeTemplate.linkFlex);
      infoLink.style.setProperty("justify-content", props.activeTemplate.linkTextJustify);
    }

    if(!props.activeTemplate.column1Flex || !props.activeTemplate.column2Flex || !col1 || !col2) return;
    if(props.activeTemplate.column1Flex && props.activeTemplate.column2Flex) {
      // col1.style.setProperty("background", props.activeTemplate.column1Clr);
      // col2.style.setProperty("background", props.activeTemplate.column2Clr);
      col1.style.setProperty("flex", props.activeTemplate.column1Flex);
      col2.style.setProperty("flex", props.activeTemplate.column2Flex);
    }

    if(!props.activeTemplate.sectionTitleBg || !props.activeTemplate.sectionTitleBorderBottom || !props.activeTemplate.titleColor || !sectionTitle1 || !sectionTitle2 || !sectionTitle3 || !sectionTitle4 || !sectionTitle5 || !sectionTitle6 || !sectionTitle7 || !sectionTitle8) return;

    if(props.activeTemplate.sectionTitleBg[0] && props.activeTemplate.sectionTitleBorderBottom[0] && sectionTitle1 && sectionTitle2 && sectionTitle3 && sectionTitle4 && sectionTitle5 && sectionTitle6 && sectionTitle7 && sectionTitle8) {
      
      sectionTitle1.style.setProperty("background", props.activeTemplate.sectionTitleBg[0]);
      sectionTitle1.style.setProperty("color", props.activeTemplate.titleColor[0]);
      sectionTitle1.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[0]);
      
      sectionTitle2.style.setProperty("background", props.activeTemplate.sectionTitleBg[1]);
      sectionTitle2.style.setProperty("color", props.activeTemplate.titleColor[1]);
      sectionTitle2.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[1]);
      
      sectionTitle3.style.setProperty("background", props.activeTemplate.sectionTitleBg[2]);
      sectionTitle3.style.setProperty("color", props.activeTemplate.titleColor[2]);
      sectionTitle3.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[2]);
      
      sectionTitle4.style.setProperty("background", props.activeTemplate.sectionTitleBg[3]);
      sectionTitle4.style.setProperty("color", props.activeTemplate.titleColor[3]);
      sectionTitle4.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[3]);
      
      sectionTitle5.style.setProperty("background", props.activeTemplate.sectionTitleBg[4]);
      sectionTitle5.style.setProperty("color", props.activeTemplate.titleColor[4]);
      sectionTitle5.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[4]);
      
      sectionTitle6.style.setProperty("background", props.activeTemplate.sectionTitleBg[5]);
      sectionTitle6.style.setProperty("color", props.activeTemplate.titleColor[5]);
      sectionTitle6.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[5]);
      
      sectionTitle7.style.setProperty("background", props.activeTemplate.sectionTitleBg[6]);
      sectionTitle7.style.setProperty("color", props.activeTemplate.titleColor[6]);
      sectionTitle7.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[6]);

      sectionTitle8.style.setProperty("background", props.activeTemplate.sectionTitleBg[7]);
      sectionTitle8.style.setProperty("color", props.activeTemplate.titleColor[7]);
      sectionTitle8.style.setProperty("border-bottom", props.activeTemplate.sectionTitleBorderBottom[7]);
      
    }
    
  }, [props]);

  return (
    <div ref={ref}>
      <div ref={containerRef} className={styles.container}>
        <div className={styles.header}>
          <p ref={nameRef} className={styles.heading}>{info.basicInfo?.detail?.name}</p>
          <p ref={titleRef} className={styles.subHeading}>{info.basicInfo?.detail?.title}</p>
          <p ref={addressRef} className={styles.addressHeading}>{info.basicInfo?.detail?.address}</p>

          <div ref={linkRef} className={styles.links}>
            {info.basicInfo?.detail?.email ? (
              <a className={styles.link} type="email">
                <AtSign /> {info.basicInfo?.detail?.email}
              </a>
            ) : (
              <span />
            )}
            {info.basicInfo?.detail?.phone ? (
              <a className={styles.link} type="phone">
                <Phone /> {info.basicInfo?.detail?.phone}
              </a>
            ) : (
              <span />
            )}
            {info.basicInfo?.detail?.linkedin ? (
              <a className={styles.link}>
                <Linkedin /> {info.basicInfo?.detail?.linkedin}
              </a>
            ) : (
              <span />
            )}
            {info.basicInfo?.detail?.github ? (
              <a className={styles.link}>
                <GitHub /> {info.basicInfo?.detail?.github}
              </a>
            ) : (
              <span />
            )}
            {info.basicInfo?.detail?.dob ? (
              <div className={styles.link}>
                <Info />{info.basicInfo?.detail?.dob}
              </div> ) : ( <span />
            )}
          </div>
        </div>

        <div className={styles.main}>
          <div ref={column1Ref} className={styles.col1}>
            {columns[0].map((item) => sectionDiv[item])}
          </div>
          <div ref={column2Ref} className={styles.col2}>
            {columns[1].map((item) => sectionDiv[item])}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Resume;
