import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { ArrowDown } from "react-feather";

import Editor from "../Editor/Editor";
import Resume from "../Resume/Resume";

import styles from "./Body.module.css";

function Body() {
  const colors = ["#239ce2", "#48bb78", "#0bc5ea", "#a0aec0", "#ed8936", "#000000", "#F7F700", "#74033E", "#e94c3d", "#3C1F1F"];
  const templates = [{
    name: "template_1",
    bgGroundClr: "#ffffff",
    allTextClr: "#000",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000",
    headingSize: "bold",
    headingAlign: "left",
    titleAlign: "left",
    addressAlign: "left",
    linkFlex: "row",
    linkTextJustify: "flex-start",
    sectionTitleBg: ["#fff","#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    titleColor: ["#000","#000","#000","#000","#000","#000","#000","#000"],
    sectionTitleBorderBottom: ["2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000"]
  }, {
    name: "template_2",
    bgGroundClr: "linear-gradient(to right, #cccccc 50%, #ffffff 50%)",
    allTextClr: "#000",
    // column1Clr: "#cccccc",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000000",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#F7F700","#F7F700","#F7F700","#F7F700","#F7F700","#F7F700","#F7F700","#F7F700"],
    titleColor: ["#000", "#000","#000","#000","#000","#000","#000","#000"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_3",
    bgGroundClr: "linear-gradient(to right, #02F4F2 50%, #ffffff 50%)",
    allTextClr: "#000",
    // column1Clr: "#02F4F2",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#74033E",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#74033E","#74033E","#74033E","#74033E","#74033E","#74033E","#74033E","#74033E"],
    titleColor: ["#fff", "#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_4",
    bgGroundClr: "url('/images/bgFloralStyle1.jpg')",
    allTextClr: "#000",
    // column1Clr: "#ffffff",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#fff","#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    titleColor: ["#000", "#000","#000","#000","#000","#000","#000","#000"],
    sectionTitleBorderBottom: ["2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000","2px solid #000"]
  }, {
    name: "template_5",
    bgGroundClr: "url('/images/bgClipsStyle1.jpg')",
    allTextClr: "#000",
    // column1Clr: "#ffffff",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#000","#000","#000","#000","#000","#000","#000","#000"],
    titleColor: ["#fff", "#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_6",
    bgGroundClr: "url('/images/bgFloralStyle2.jpg')",
    allTextClr: "#000",
    // column1Clr: "#ffffff",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#000","#000","#000","#000","#000","#000","#000","#000"],
    titleColor: ["#fff", "#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_7",
    bgGroundClr: "url('/images/RoundedCornersStyle1.jpg')",
    allTextClr: "#000",
    // column1Clr: "#ffffff",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#000",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#2b3657","#2b3657","#2b3657","#2b3657","#2b3657","#2b3657","#2b3657","#2b3657"],
    titleColor: ["#fff", "#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_8",
    bgGroundClr: "linear-gradient(to right, #ffffff 41%, #ead5c0 41%)",
    allTextClr: "#000",
    // column1Clr: "#cccccc",
    // column2Clr: "#ffffff",
    column1Flex: "0.8",
    column2Flex: "1.2",
    headingColor: "#573414",
    headingSize: "bold",
    headingAlign: "left",
    titleAlign: "left",
    addressAlign: "left",
    linkFlex: "row",
    linkTextJustify: "flex-start",
    sectionTitleBg: ["#fff","#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    titleColor: ["#573414", "#573414","#573414","#573414","#573414","#573414","#573414","#573414"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_9",
    bgGroundClr: "linear-gradient(to right, #ecf0f1 41%, #e94c3d 41%)",
    allTextClr: "#000",
    // column1Clr: "#cccccc",
    // column2Clr: "#ffffff",
    column1Flex: "0.8",
    column2Flex: "1.2",
    headingColor: "#000000",
    headingSize: "bold",
    headingAlign: "left",
    titleAlign: "left",
    addressAlign: "left",
    linkFlex: "row",
    linkTextJustify: "flex-start",
    sectionTitleBg: ["#fff","#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    titleColor: ["#e94c3d", "#e94c3d","#e94c3d","#e94c3d","#e94c3d","#e94c3d","#e94c3d","#e94c3d"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_10",
    bgGroundClr: "#15104a",
    allTextClr: "#fff",
    // column1Clr: "#cccccc",
    // column2Clr: "#ffffff",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#fff",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#eb008b","#eb008b","#eb008b","#eb008b","#eb008b","#eb008b","#eb008b","#eb008b"],
    titleColor: ["#fff", "#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["0","0","0","0","0","0","0","0"]
  }, {
    name: "template_11",
    bgGroundClr: "linear-gradient(to bottom, #3C1F1F 12%, #414143 12%)",
    allTextClr: "#ccc",
    column1Flex: "1",
    column2Flex: "1",
    headingColor: "#fab142",
    headingSize: "bold",
    headingAlign: "center",
    titleAlign: "center",
    addressAlign: "center",
    linkFlex: "row",
    linkTextJustify: "center",
    sectionTitleBg: ["#414143","#414143","#414143","#414143","#414143","#414143","#414143","#414143"],
    titleColor: ["#fff","#fff","#fff","#fff","#fff","#fff","#fff","#fff"],
    sectionTitleBorderBottom: ["2px solid #fab142","2px solid #fab142","2px solid #fab142","2px solid #fab142","2px solid #fab142","2px solid #fab142","2px solid #fab142","2px solid #fab142"]
  }];
  const sections = {
    basicInfo: "Basic Info",
    workExp: "Work Experience",
    project: "Projects",
    skills: "Skills",
    education: "Education",
    achievement: "Achievements",
    summary: "Summary",
    hobbies: "Hobbies",
    other: "Other",
  };
  const resumeRef = useRef();

  const [activeColor, setActiveColor] = useState(colors[0]);
  const [activeTemplate, setActiveTemplate] = useState(templates[0]);
  const [resumeInformation, setResumeInformation] = useState({
    [sections.basicInfo]: {
      id: sections.basicInfo,
      sectionTitle: sections.basicInfo,
      detail: {},
    },
    [sections.workExp]: {
      id: sections.workExp,
      sectionTitle: sections.workExp,
      details: [],
    },
    [sections.project]: {
      id: sections.project,
      sectionTitle: sections.project,
      details: [],
    },
    [sections.education]: {
      id: sections.education,
      sectionTitle: sections.education,
      details: [],
    },
    [sections.skills]: {
      id: sections.skills,
      sectionTitle: sections.skills,
      points: [],
    },
    [sections.achievement]: {
      id: sections.achievement,
      sectionTitle: sections.achievement,
      points: [],
    },
    [sections.summary]: {
      id: sections.summary,
      sectionTitle: sections.summary,
      detail: "",
    },
    [sections.hobbies]: {
      id: sections.hobbies,
      sectionTitle: sections.hobbies,
      detail: "",
    },
    [sections.other]: {
      id: sections.other,
      sectionTitle: sections.other,
      detail: "",
    },
  });

  return (
    <div className={styles.container}>
      <p className={styles.heading}>ANW Mark Resume Creator</p>
      <div className={styles.toolbar}>
        <div className={styles.colors}>
          {colors.map((item) => (
            <span
              key={item}
              style={{ backgroundColor: item }}
              className={`${styles.color} ${
                activeColor === item ? styles.active : ""
              }`}
              onClick={() => setActiveColor(item)}
            />
          ))}
        </div>
        <div className={styles.templates}>
        {templates.map((item) => (
            <span
              key={item.name}
              // style={{ backgroundColor: item.bgGroundClr }}
              className={`${styles.template} ${
                activeTemplate.name === item.name ? styles.active : ""
              }`}
              onClick={() => setActiveTemplate(item)}
            />
          ))}
        </div>
        <ReactToPrint
          trigger={() => {
            return (
              <button>
                Download Resume <ArrowDown />
              </button>
            );
          }}
          content={() => resumeRef.current}
        />
      </div>
      <div className={styles.main}>
        <Editor
          sections={sections}
          information={resumeInformation}
          setInformation={setResumeInformation}
        />
        <Resume
          ref={resumeRef}
          sections={sections}
          information={resumeInformation}
          activeColor={activeColor}
          activeTemplate={activeTemplate}
        />
      </div>
    </div>
  );
}

export default Body;
